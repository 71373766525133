

















































import { NftModel } from "@/models/nft-model";
import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({
  components: {
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
  },
})
export default class CharacterCard extends Vue {
  @Prop({ default: 1 }) index!: number;
  @Prop() character!: NftModel;

  openCharacterDetail() {
    this.$router.push(`/nft/${this.character.ID}`);
  }
}
